const ROUTES = {
  "/": {
    "name": "HomePage3fdc304cF45e4e6a98e4Ee94fec50205",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPagesDfe2c6093a5a4385A6bd27081992fa4e",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPage4b26008cD4b249c0B9a2B4b5fbb9e886",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/about/leveling": {
    "name": "AboutLevelingPageF37e8b35D7f64871A212D510fd67e33e",
    "type": "HTML",
    "key": "about-leveling-page"
  },
  "/events/public": {
    "name": "PublicEventsPage99001a92Aabe432586741ea64387bc51",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPage5d71f2beC6234432A32d660046472c47",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/members/*": {
    "name": "MemberPagesBa865d014924405180b2C178566dfad9",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePages2ee8e868794b417280afA150a3cc6cc9",
    "type": "HTML",
    "key": "message-pages"
  },
  "/resources": {
    "name": "ResourcesPage671ef0ec709943af822e8eb87166fc2f",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPages4d5d358d8ecf469bA4f4C151267729cd",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;