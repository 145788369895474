import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "0b8fc997-596e-4661-b484-46fa62912926"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCards0b8fc997596e4661B48446fa62912926(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCards0b8fc997596e4661B48446fa62912926", parentTag)} postSlug="home-page-posts2" buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" {...props} />
  );
}

// id: "a8140ffa-4e66-4e1d-bf19-5d0c9d624e24"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const TitleA8140ffa4e664e1dBf195d0c9d624e24 = "Leveling";

// id: "dfe2c609-3a5a-4385-a6bd-27081992fa4e"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPagesDfe2c6093a5a4385A6bd27081992fa4e(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPagesDfe2c6093a5a4385A6bd27081992fa4e`}>
      {props.children}
    </div>
  );
}

// id: "44921788-895a-4aed-a779-1dca511702c9"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeader44921788895a4aedA7791dca511702c9(props: any) {
  return (
    <ShortHeader67c7f2661f67440fB41fD39343ca6b07 parentTag="DefaultHeader44921788895a4aedA7791dca511702c9" title navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "111d7a13-e272-4dde-9655-15fb014dcec1"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeader111d7a13E2724dde965515fb014dcec1(props: any) {
  return (
    <ShortHeader67c7f2661f67440fB41fD39343ca6b07 parentTag="MembersAreaHeader111d7a13E2724dde965515fb014dcec1" header-top={<DefaultHeaderTop6a917703E60b4ec19fad54e201b22778 parentTag="HeaderTop6149a25a8aff42718c4015e87519acf4" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "269d447f-b8c1-4e3b-bc0f-beedad2c6641"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBody269d447fB8c14e3bBc0fBeedad2c6641(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBody269d447fB8c14e3bBc0fBeedad2c6641 page-body`}>
      {props["marketingCards"] || <MarketingCards0b8fc997596e4661B48446fa62912926 parentTag="MarketingCards304fa97b998d44929632E0e2413519e0" postSlug="home-page-posts2" {...props} />}
    </div>
  );
}

// id: "4b26008c-d4b2-49c0-b9a2-b4b5fbb9e886"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPage4b26008cD4b249c0B9a2B4b5fbb9e886(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPage4b26008cD4b249c0B9a2B4b5fbb9e886 page`}>
      {props["header"] || <SecondaryMarketingHeader395ff4ef7e4840e8Be122b696fccbabb parentTag="HeaderF964d60cD7d3482eA38a40a5b19e37ef" title="About Us" {...props} />}
      {props["body"] || <MarketingBody269d447fB8c14e3bBc0fBeedad2c6641 parentTag="Body2af94536976746118965530a4f4f6187" postSlug="about-us-posts" {...props} />}
      {props["footer"] || <DefaultFooterC4e10cd87a27467e9d3167b01152ced7 parentTag="Footer0c12e265Ccff4a898ea48909c55a5f7a" {...props} />}
    </div>
  );
}

// id: "99001a92-aabe-4325-8674-1ea64387bc51"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPage99001a92Aabe432586741ea64387bc51(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPage99001a92Aabe432586741ea64387bc51`}>
      {props["header"] || <SecondaryMarketingHeader395ff4ef7e4840e8Be122b696fccbabb parentTag="HeaderF97d162c30b945fbAa297c29cb69067f" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterC4e10cd87a27467e9d3167b01152ced7 parentTag="Footer46885dbeF2d347069d4c1139c86c0f2e" {...props} />}
    </div>
  );
}

// id: "ba865d01-4924-4051-80b2-c178566dfad9"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPagesBa865d014924405180b2C178566dfad9(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPagesBa865d014924405180b2C178566dfad9 gf-page-layout`}>
      {props["header"] || <MembersAreaHeader111d7a13E2724dde965515fb014dcec1 parentTag="HeaderC6492429Ce7e4a7990c6473249c61911" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNavC9877a1eE9f9424c90839e42a133ed94", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterC4e10cd87a27467e9d3167b01152ced7 parentTag="Footer9600907384c0460398d182b096c81a68" {...props} />}
    </div>
  );
}

// id: "7dbe3b1f-bb92-42f9-91ef-381d4f3656d0"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefault7dbe3b1fBb9242f991ef381d4f3656d0(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefault7dbe3b1fBb9242f991ef381d4f3656d0 page`}>
      {props["header"] || <MembersAreaHeader111d7a13E2724dde965515fb014dcec1 parentTag="Header3a2522c19bd84aee881327bb913fc017" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooterC4e10cd87a27467e9d3167b01152ced7 parentTag="Footer773850abC14d40f6A71c9f0ec5e762fe" {...props} />}
    </div>
  );
}

// id: "68d96fba-49d8-4b83-9203-dd8cdbe01d06"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPage68d96fba49d84b839203Dd8cdbe01d06(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPage68d96fba49d84b839203Dd8cdbe01d06`}>
      {props["header"] || <SecondaryMarketingHeader395ff4ef7e4840e8Be122b696fccbabb parentTag="HeaderA30dddf99c4b4cd5B2bc342f5d7aaeb3" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooterC4e10cd87a27467e9d3167b01152ced7 parentTag="FooterF229604286c245a89c85A4e5349d8491" {...props} />}
    </div>
  );
}

// id: "671ef0ec-7099-43af-822e-8eb87166fc2f"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPage671ef0ec709943af822e8eb87166fc2f(props: any) {
  return (
    <SecondaryPage68d96fba49d84b839203Dd8cdbe01d06 parentTag="ResourcesPage671ef0ec709943af822e8eb87166fc2f" header={<SecondaryMarketingHeader395ff4ef7e4840e8Be122b696fccbabb parentTag="HeaderDec536df11ae42548976Bfeffe75d3ab" title="Resources" />} {...props} />
  );
}

// id: "123a7d7b-19a6-49fa-b241-09eeea07e724"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function Label123a7d7b19a649faB24109eeea07e724(props: any) {
  return (
    <DefaultLogoF2b321d565694ba098f384dd39ede492 parentTag="Label123a7d7b19a649faB24109eeea07e724" {...props} />
  );
}

// id: "67c7f266-1f67-440f-b41f-d39343ca6b07"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeader67c7f2661f67440fB41fD39343ca6b07(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeader67c7f2661f67440fB41fD39343ca6b07 site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo392ec5cc4ca64bea86a3008dbeae6c91", parentTag)} label={<DefaultLogoF2b321d565694ba098f384dd39ede492 parentTag="LabelBd452c75C56e44ccBef85f929945581e" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation90cd00124bd34dcfBb397d070a0f1740", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "f2b321d5-6569-4ba0-98f3-84dd39ede492"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogoF2b321d565694ba098f384dd39ede492(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide.svg" className={`${parentTag || ""} DefaultLogoF2b321d565694ba098f384dd39ede492 logo`} alt="logo" />
  );
}

// id: "d5c8fdfb-7715-470c-8768-4eca61a00fae"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuoteD5c8fdfb7715470c87684eca61a00fae(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuoteD5c8fdfb7715470c87684eca61a00fae`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "5d71f2be-c623-4432-a32d-660046472c47"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPage5d71f2beC6234432A32d660046472c47(props: any) {
  return (
    <SecondaryPage68d96fba49d84b839203Dd8cdbe01d06 parentTag="JoinUsPage5d71f2beC6234432A32d660046472c47" header={<SecondaryMarketingHeader395ff4ef7e4840e8Be122b696fccbabb parentTag="Header133027f0198f47d0A45377913806344f" title="Join Us" />} {...props} />
  );
}

// id: "d45755b5-a243-4f97-b69a-eee167be90d1"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const HrefD45755b5A2434f97B69aEee167be90d1 = "/";

// id: "d0222598-9637-4532-b438-e139acc5bc24"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function FooterD022259896374532B438E139acc5bc24(props: any) {
  return (
    <DefaultFooterC4e10cd87a27467e9d3167b01152ced7 parentTag="FooterD022259896374532B438E139acc5bc24" {...props} />
  );
}

// id: "4d5d358d-8ecf-469b-a4f4-c151267729cd"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPages4d5d358d8ecf469bA4f4C151267729cd(props: any) {
  return (
    <SecondaryPage68d96fba49d84b839203Dd8cdbe01d06 parentTag="SignupPages4d5d358d8ecf469bA4f4C151267729cd" header={<SecondaryMarketingHeader395ff4ef7e4840e8Be122b696fccbabb parentTag="Header176c10aa4f7c41e19d5dF9d0060ee78e" title="Join Us" />} {...props} />
  );
}

// id: "a470ec2c-278f-490a-85e0-d0ec554f17ab"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHeroA470ec2c278f490a85e0D0ec554f17ab(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeroA470ec2c278f490a85e0D0ec554f17ab hero`}>
      <div className="title">
        {props["title"] || Title0e173fbdF4164381B78b058233633748}
      </div>
    </div>
  );
}

// id: "3414f881-7ba3-46f4-b83d-a24e8f07362b"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function Header3414f8817ba346f4B83dA24e8f07362b(props: any) {
  return (
    <MembersAreaHeader111d7a13E2724dde965515fb014dcec1 parentTag="Header3414f8817ba346f4B83dA24e8f07362b" {...props} />
  );
}

// id: "c4e10cd8-7a27-467e-9d31-67b01152ced7"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooterC4e10cd87a27467e9d3167b01152ced7(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooterC4e10cd87a27467e9d3167b01152ced7 footer`}>
      {props["links"] || <Navigation parentTag={buildClassName("Links029ec10739784b0e9326D630f0eba006", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <Logo86000eeeDfb54e19A37b3a0a7f5d3a46 />}
      </div>
      {props["copyright"] || <GroupCopyright parentTag={buildClassName("CopyrightDaad1c66657249d59e2a91e7dc899199", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("Groupflow8d3b0113C4d34b8a92e7B929d42247aa", parentTag)} {...props} />}
    </div>
  );
}

// id: "6a917703-e60b-4ec1-9fad-54e201b22778"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTop6a917703E60b4ec19fad54e201b22778(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTop6a917703E60b4ec19fad54e201b22778 header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo594096430abd4fb39ae1Dea5b2db546c", parentTag)} url="/" className="linked-logo" label={<DefaultLogoF2b321d565694ba098f384dd39ede492 parentTag="LabelF81780f93ff24092A1c9E4a2f25f1c95" alt="logo" />} {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("NavigationB7773ab893e6458f99a7C989320a1dd4", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />}
    </div>
  );
}

// id: "f37e8b35-d7f6-4871-a212-d510fd67e33e"
// title: "Levels 1-4 Explained"
// type: :html
// key: "about-leveling-page"
// parent_id: nil
export function AboutLevelingPageF37e8b35D7f64871A212D510fd67e33e(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutLevelingPageF37e8b35D7f64871A212D510fd67e33e`}>
      {props["header"] || <SecondaryMarketingHeader395ff4ef7e4840e8Be122b696fccbabb parentTag="Header18a056fe22db4154A276F60d0652a333" title="Leveling" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterC4e10cd87a27467e9d3167b01152ced7 parentTag="FooterAfcaa2b0C3d64f2899aeC6623c8e74e1" {...props} />}
    </div>
  );
}

// id: "2ee8e868-794b-4172-80af-a150a3cc6cc9"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePages2ee8e868794b417280afA150a3cc6cc9(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePages2ee8e868794b417280afA150a3cc6cc9`}>
      {props["header"] || <MembersAreaHeader111d7a13E2724dde965515fb014dcec1 parentTag="HeaderBccf4639Aed74379B74cEb3ee12dc401" {...props} />}
      {props.children}
    </div>
  );
}

// id: "b9e27b8d-a746-4095-8348-69414698e478"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHeroB9e27b8dA7464095834869414698e478(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHeroB9e27b8dA7464095834869414698e478 hero`}>
      <div className="title">
        Our mission is to change the world by promoting healthy intimacy through community and education.
      </div>
      <div className="button">
        {props["button"] || <Button parentTag={buildClassName("Button3ea120c78e494b00A8be6553018b111b", parentTag)} href="/join" label="Join Us" {...props} />}
      </div>
    </div>
  );
}

// id: "395ff4ef-7e48-40e8-be12-2b696fccbabb"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeader395ff4ef7e4840e8Be122b696fccbabb(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeader395ff4ef7e4840e8Be122b696fccbabb site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop6a917703E60b4ec19fad54e201b22778 parentTag="HeaderTopE2961735Dc7546eaB59152fe4f5d02d8" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHeroA470ec2c278f490a85e0D0ec554f17ab parentTag="HeroA98ede74D2b84112Acbc05e3c397afd3" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "3fdc304c-f45e-4e6a-98e4-ee94fec50205"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePage3fdc304cF45e4e6a98e4Ee94fec50205(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePage3fdc304cF45e4e6a98e4Ee94fec50205 page`}>
      {props["header"] || <HomeHeader2b967b365a91417f92b1223f56813936 parentTag="HeaderF0b82a4177ae4556A1080fa7f4206382" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack4adc5d7b4b3b41e7Bc8571a6e38d75ff", parentTag)} {...props} />}
      {props["body"] || <MarketingBody269d447fB8c14e3bBc0fBeedad2c6641 parentTag="BodyB71d2d8a1c984776B0b83c8b8ac2e00b" {...props} />}
      {props["quote"] || <PageQuote parentTag={buildClassName("QuoteE7bc675d29a84f51Aed1E26a74e6e0a3", parentTag)} contentSlug="home-page-quote" {...props} />}
      {props["footer"] || <DefaultFooterC4e10cd87a27467e9d3167b01152ced7 parentTag="Footer4a927a4b20de479aA04857c8967ee145" {...props} />}
    </div>
  );
}

// id: "2b967b36-5a91-417f-92b1-223f56813936"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeader2b967b365a91417f92b1223f56813936(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeader2b967b365a91417f92b1223f56813936 site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop6a917703E60b4ec19fad54e201b22778 parentTag="HeaderTopEf6f14cd0d2444e9Bf3495944e9c350e" widgetId="6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5" {...props} />}
      </div>
      {props["hero"] || <HomePageHeroB9e27b8dA7464095834869414698e478 parentTag="Hero379c76f75ee24f4bB891500f919db192" widgetId="4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d" {...props} />}
    </div>
  );
}

// id: "029ec107-3978-4b0e-9326-d630f0eba006"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "c4e10cd8-7a27-467e-9d31-67b01152ced7"
export function Links029ec10739784b0e9326D630f0eba006(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("Links029ec10739784b0e9326D630f0eba006", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "a98ede74-d2b8-4112-acbc-05e3c397afd3"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "395ff4ef-7e48-40e8-be12-2b696fccbabb"
export function HeroA98ede74D2b84112Acbc05e3c397afd3(props: any) {
  return (
    <SecondaryMarketingHeroA470ec2c278f490a85e0D0ec554f17ab parentTag="HeroA98ede74D2b84112Acbc05e3c397afd3" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "96009073-84c0-4603-98d1-82b096c81a68"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "ba865d01-4924-4051-80b2-c178566dfad9"
export function Footer9600907384c0460398d182b096c81a68(props: any) {
  return (
    <DefaultFooterC4e10cd87a27467e9d3167b01152ced7 parentTag="Footer9600907384c0460398d182b096c81a68" {...props} />
  );
}

// id: "3a2522c1-9bd8-4aee-8813-27bb913fc017"
// title: ""
// type: :reference
// key: "header"
// parent_id: "7dbe3b1f-bb92-42f9-91ef-381d4f3656d0"
export function Header3a2522c19bd84aee881327bb913fc017(props: any) {
  return (
    <MembersAreaHeader111d7a13E2724dde965515fb014dcec1 parentTag="Header3a2522c19bd84aee881327bb913fc017" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "c6492429-ce7e-4a79-90c6-473249c61911"
// title: ""
// type: :reference
// key: "header"
// parent_id: "ba865d01-4924-4051-80b2-c178566dfad9"
export function HeaderC6492429Ce7e4a7990c6473249c61911(props: any) {
  return (
    <MembersAreaHeader111d7a13E2724dde965515fb014dcec1 parentTag="HeaderC6492429Ce7e4a7990c6473249c61911" {...props} />
  );
}

// id: "f0b82a41-77ae-4556-a108-0fa7f4206382"
// title: ""
// type: :reference
// key: "header"
// parent_id: "3fdc304c-f45e-4e6a-98e4-ee94fec50205"
export function HeaderF0b82a4177ae4556A1080fa7f4206382(props: any) {
  return (
    <HomeHeader2b967b365a91417f92b1223f56813936 parentTag="HeaderF0b82a4177ae4556A1080fa7f4206382" {...props} />
  );
}

// id: "4adc5d7b-4b3b-41e7-bc85-71a6e38d75ff"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "3fdc304c-f45e-4e6a-98e4-ee94fec50205"
export function WelcomeBack4adc5d7b4b3b41e7Bc8571a6e38d75ff(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack4adc5d7b4b3b41e7Bc8571a6e38d75ff", parentTag)} {...props} />
  );
}

// id: "0c12e265-ccff-4a89-8ea4-8909c55a5f7a"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "4b26008c-d4b2-49c0-b9a2-b4b5fbb9e886"
export function Footer0c12e265Ccff4a898ea48909c55a5f7a(props: any) {
  return (
    <DefaultFooterC4e10cd87a27467e9d3167b01152ced7 parentTag="Footer0c12e265Ccff4a898ea48909c55a5f7a" {...props} />
  );
}

// id: "773850ab-c14d-40f6-a71c-9f0ec5e762fe"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "7dbe3b1f-bb92-42f9-91ef-381d4f3656d0"
export function Footer773850abC14d40f6A71c9f0ec5e762fe(props: any) {
  return (
    <DefaultFooterC4e10cd87a27467e9d3167b01152ced7 parentTag="Footer773850abC14d40f6A71c9f0ec5e762fe" {...props} />
  );
}

// id: "4a927a4b-20de-479a-a048-57c8967ee145"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "3fdc304c-f45e-4e6a-98e4-ee94fec50205"
export function Footer4a927a4b20de479aA04857c8967ee145(props: any) {
  return (
    <DefaultFooterC4e10cd87a27467e9d3167b01152ced7 parentTag="Footer4a927a4b20de479aA04857c8967ee145" {...props} />
  );
}

// id: "a30dddf9-9c4b-4cd5-b2bc-342f5d7aaeb3"
// title: ""
// type: :reference
// key: "header"
// parent_id: "68d96fba-49d8-4b83-9203-dd8cdbe01d06"
export function HeaderA30dddf99c4b4cd5B2bc342f5d7aaeb3(props: any) {
  return (
    <SecondaryMarketingHeader395ff4ef7e4840e8Be122b696fccbabb parentTag="HeaderA30dddf99c4b4cd5B2bc342f5d7aaeb3" title="Secondary Page" {...props} />
  );
}

// id: "fc948351-cfdb-42a1-983d-27c8c7aa0e72"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "0b8fc997-596e-4661-b484-46fa62912926"
export const PostSlugFc948351Cfdb42a1983d27c8c7aa0e72 = "home-page-posts2";

// id: "bccf4639-aed7-4379-b74c-eb3ee12dc401"
// title: ""
// type: :reference
// key: "header"
// parent_id: "2ee8e868-794b-4172-80af-a150a3cc6cc9"
export function HeaderBccf4639Aed74379B74cEb3ee12dc401(props: any) {
  return (
    <MembersAreaHeader111d7a13E2724dde965515fb014dcec1 parentTag="HeaderBccf4639Aed74379B74cEb3ee12dc401" {...props} />
  );
}

// id: "59409643-0abd-4fb3-9ae1-dea5b2db546c"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "6a917703-e60b-4ec1-9fad-54e201b22778"
export function LinkedLogo594096430abd4fb39ae1Dea5b2db546c(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo594096430abd4fb39ae1Dea5b2db546c", parentTag)} url="/" className="linked-logo" label={<DefaultLogoF2b321d565694ba098f384dd39ede492 parentTag="LabelF81780f93ff24092A1c9E4a2f25f1c95" alt="logo" />} {...props} />
  );
}

// id: "b71d2d8a-1c98-4776-b0b8-3c8b8ac2e00b"
// title: ""
// type: :reference
// key: "body"
// parent_id: "3fdc304c-f45e-4e6a-98e4-ee94fec50205"
export function BodyB71d2d8a1c984776B0b83c8b8ac2e00b(props: any) {
  return (
    <MarketingBody269d447fB8c14e3bBc0fBeedad2c6641 parentTag="BodyB71d2d8a1c984776B0b83c8b8ac2e00b" {...props} />
  );
}

// id: "18a056fe-22db-4154-a276-f60d0652a333"
// title: ""
// type: :reference
// key: "header"
// parent_id: "f37e8b35-d7f6-4871-a212-d510fd67e33e"
export function Header18a056fe22db4154A276F60d0652a333(props: any) {
  return (
    <SecondaryMarketingHeader395ff4ef7e4840e8Be122b696fccbabb parentTag="Header18a056fe22db4154A276F60d0652a333" title="Leveling" {...props} />
  );
}

// id: "379c76f7-5ee2-4f4b-b891-500f919db192"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "2b967b36-5a91-417f-92b1-223f56813936"
export function Hero379c76f75ee24f4bB891500f919db192(props: any) {
  return (
    <HomePageHeroB9e27b8dA7464095834869414698e478 parentTag="Hero379c76f75ee24f4bB891500f919db192" widgetId="4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d" {...props} />
  );
}

// id: "25930853-1bdd-4c9a-af0c-eac714258e3c"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "0b8fc997-596e-4661-b484-46fa62912926"
export const ButtonClass259308531bdd4c9aAf0cEac714258e3c = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "c9877a1e-e9f9-424c-9083-9e42a133ed94"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "ba865d01-4924-4051-80b2-c178566dfad9"
export function SecondaryNavC9877a1eE9f9424c90839e42a133ed94(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNavC9877a1eE9f9424c90839e42a133ed94", parentTag)} {...props} />
  );
}

// id: "daad1c66-6572-49d5-9e2a-91e7dc899199"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "c4e10cd8-7a27-467e-9d31-67b01152ced7"
export function CopyrightDaad1c66657249d59e2a91e7dc899199(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("CopyrightDaad1c66657249d59e2a91e7dc899199", parentTag)} {...props} />
  );
}

// id: "392ec5cc-4ca6-4bea-86a3-008dbeae6c91"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "67c7f266-1f67-440f-b41f-d39343ca6b07"
export function LinkedLogo392ec5cc4ca64bea86a3008dbeae6c91(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo392ec5cc4ca64bea86a3008dbeae6c91", parentTag)} label={<DefaultLogoF2b321d565694ba098f384dd39ede492 parentTag="LabelBd452c75C56e44ccBef85f929945581e" alt="logo" />} {...props} />
  );
}

// id: "176c10aa-4f7c-41e1-9d5d-f9d0060ee78e"
// title: ""
// type: :reference
// key: "header"
// parent_id: "4d5d358d-8ecf-469b-a4f4-c151267729cd"
export function Header176c10aa4f7c41e19d5dF9d0060ee78e(props: any) {
  return (
    <SecondaryMarketingHeader395ff4ef7e4840e8Be122b696fccbabb parentTag="Header176c10aa4f7c41e19d5dF9d0060ee78e" title="Join Us" {...props} />
  );
}

// id: "a7c3ec1e-c24f-4ca1-ab6d-4dd6f09d914d"
// title: ""
// type: :text
// key: "title"
// parent_id: "44921788-895a-4aed-a779-1dca511702c9"
export const TitleA7c3ec1eC24f4ca1Ab6d4dd6f09d914d = null;

// id: "c7d88055-784f-4f69-9a99-77d85e2bf967"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "67c7f266-1f67-440f-b41f-d39343ca6b07"
export const BackgroundImageC7d88055784f4f699a9977d85e2bf967 = "https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg";

// id: "304fa97b-998d-4492-9632-e0e2413519e0"
// title: ""
// type: :reference
// key: "marketingCards"
// parent_id: "269d447f-b8c1-4e3b-bc0f-beedad2c6641"
export function MarketingCards304fa97b998d44929632E0e2413519e0(props: any) {
  return (
    <MarketingCards0b8fc997596e4661B48446fa62912926 parentTag="MarketingCards304fa97b998d44929632E0e2413519e0" postSlug="home-page-posts2" {...props} />
  );
}

// id: "3ea120c7-8e49-4b00-a8be-6553018b111b"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "b9e27b8d-a746-4095-8348-69414698e478"
export function Button3ea120c78e494b00A8be6553018b111b(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("Button3ea120c78e494b00A8be6553018b111b", parentTag)} href="/join" label="Join Us" {...props} />
  );
}

// id: "f97d162c-30b9-45fb-aa29-7c29cb69067f"
// title: ""
// type: :reference
// key: "header"
// parent_id: "99001a92-aabe-4325-8674-1ea64387bc51"
export function HeaderF97d162c30b945fbAa297c29cb69067f(props: any) {
  return (
    <SecondaryMarketingHeader395ff4ef7e4840e8Be122b696fccbabb parentTag="HeaderF97d162c30b945fbAa297c29cb69067f" title="Public Events" {...props} />
  );
}

// id: "f2296042-86c2-45a8-9c85-a4e5349d8491"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "68d96fba-49d8-4b83-9203-dd8cdbe01d06"
export function FooterF229604286c245a89c85A4e5349d8491(props: any) {
  return (
    <DefaultFooterC4e10cd87a27467e9d3167b01152ced7 parentTag="FooterF229604286c245a89c85A4e5349d8491" {...props} />
  );
}

// id: "afcaa2b0-c3d6-4f28-99ae-c6623c8e74e1"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "f37e8b35-d7f6-4871-a212-d510fd67e33e"
export function FooterAfcaa2b0C3d64f2899aeC6623c8e74e1(props: any) {
  return (
    <DefaultFooterC4e10cd87a27467e9d3167b01152ced7 parentTag="FooterAfcaa2b0C3d64f2899aeC6623c8e74e1" {...props} />
  );
}

// id: "b7773ab8-93e6-458f-99a7-c989320a1dd4"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "6a917703-e60b-4ec1-9fad-54e201b22778"
export function NavigationB7773ab893e6458f99a7C989320a1dd4(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("NavigationB7773ab893e6458f99a7C989320a1dd4", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "f964d60c-d7d3-482e-a38a-40a5b19e37ef"
// title: ""
// type: :reference
// key: "header"
// parent_id: "4b26008c-d4b2-49c0-b9a2-b4b5fbb9e886"
export function HeaderF964d60cD7d3482eA38a40a5b19e37ef(props: any) {
  return (
    <SecondaryMarketingHeader395ff4ef7e4840e8Be122b696fccbabb parentTag="HeaderF964d60cD7d3482eA38a40a5b19e37ef" title="About Us" {...props} />
  );
}

// id: "e2961735-dc75-46ea-b591-52fe4f5d02d8"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "395ff4ef-7e48-40e8-be12-2b696fccbabb"
export function HeaderTopE2961735Dc7546eaB59152fe4f5d02d8(props: any) {
  return (
    <DefaultHeaderTop6a917703E60b4ec19fad54e201b22778 parentTag="HeaderTopE2961735Dc7546eaB59152fe4f5d02d8" {...props} />
  );
}

// id: "46885dbe-f2d3-4706-9d4c-1139c86c0f2e"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "99001a92-aabe-4325-8674-1ea64387bc51"
export function Footer46885dbeF2d347069d4c1139c86c0f2e(props: any) {
  return (
    <DefaultFooterC4e10cd87a27467e9d3167b01152ced7 parentTag="Footer46885dbeF2d347069d4c1139c86c0f2e" {...props} />
  );
}

// id: "0e173fbd-f416-4381-b78b-058233633748"
// title: ""
// type: :text
// key: "title"
// parent_id: "a470ec2c-278f-490a-85e0-d0ec554f17ab"
export const Title0e173fbdF4164381B78b058233633748 = "Default Title";

// id: "dec536df-11ae-4254-8976-bfeffe75d3ab"
// title: ""
// type: :reference
// key: "header"
// parent_id: "671ef0ec-7099-43af-822e-8eb87166fc2f"
export function HeaderDec536df11ae42548976Bfeffe75d3ab(props: any) {
  return (
    <SecondaryMarketingHeader395ff4ef7e4840e8Be122b696fccbabb parentTag="HeaderDec536df11ae42548976Bfeffe75d3ab" title="Resources" {...props} />
  );
}

// id: "14c19279-4119-4379-9257-ec2587c3f567"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "2b967b36-5a91-417f-92b1-223f56813936"
export const BackgroundImage14c19279411943799257Ec2587c3f567 = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/Moving_Forest_1050_700.webp";

// id: "90cd0012-4bd3-4dcf-bb39-7d070a0f1740"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "67c7f266-1f67-440f-b41f-d39343ca6b07"
export function Navigation90cd00124bd34dcfBb397d070a0f1740(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation90cd00124bd34dcfBb397d070a0f1740", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "86000eee-dfb5-4e19-a37b-3a0a7f5d3a46"
// title: ""
// type: :html
// key: "logo"
// parent_id: "c4e10cd8-7a27-467e-9d31-67b01152ced7"
export function Logo86000eeeDfb54e19A37b3a0a7f5d3a46(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide-white-with-font.svg" alt="Demo 1 logo" className={`${parentTag || ""} Logo86000eeeDfb54e19A37b3a0a7f5d3a46 logo`} />
  );
}

// id: "6149a25a-8aff-4271-8c40-15e87519acf4"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "111d7a13-e272-4dde-9655-15fb014dcec1"
export function HeaderTop6149a25a8aff42718c4015e87519acf4(props: any) {
  return (
    <DefaultHeaderTop6a917703E60b4ec19fad54e201b22778 parentTag="HeaderTop6149a25a8aff42718c4015e87519acf4" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "133027f0-198f-47d0-a453-77913806344f"
// title: ""
// type: :reference
// key: "header"
// parent_id: "5d71f2be-c623-4432-a32d-660046472c47"
export function Header133027f0198f47d0A45377913806344f(props: any) {
  return (
    <SecondaryMarketingHeader395ff4ef7e4840e8Be122b696fccbabb parentTag="Header133027f0198f47d0A45377913806344f" title="Join Us" {...props} />
  );
}

// id: "5122a14e-3422-47da-90c0-422f3b896294"
// title: ""
// type: :text
// key: "alt"
// parent_id: "f2b321d5-6569-4ba0-98f3-84dd39ede492"
export const Alt5122a14e342247da90c0422f3b896294 = "logo";

// id: "8d3b0113-c4d3-4b8a-92e7-b929d42247aa"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "c4e10cd8-7a27-467e-9d31-67b01152ced7"
export function Groupflow8d3b0113C4d34b8a92e7B929d42247aa(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("Groupflow8d3b0113C4d34b8a92e7B929d42247aa", parentTag)} {...props} />
  );
}

// id: "65fafe8f-5f00-4854-ba20-b6cf67802824"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "44921788-895a-4aed-a779-1dca511702c9"
export const NavMenuSlug65fafe8f5f004854Ba20B6cf67802824 = "marketing-primary-nav";

// id: "2af94536-9767-4611-8965-530a4f4f6187"
// title: ""
// type: :reference
// key: "body"
// parent_id: "4b26008c-d4b2-49c0-b9a2-b4b5fbb9e886"
export function Body2af94536976746118965530a4f4f6187(props: any) {
  return (
    <MarketingBody269d447fB8c14e3bBc0fBeedad2c6641 parentTag="Body2af94536976746118965530a4f4f6187" postSlug="about-us-posts" {...props} />
  );
}

// id: "e7bc675d-29a8-4f51-aed1-e26a74e6e0a3"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "3fdc304c-f45e-4e6a-98e4-ee94fec50205"
export function QuoteE7bc675d29a84f51Aed1E26a74e6e0a3(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote parentTag={buildClassName("QuoteE7bc675d29a84f51Aed1E26a74e6e0a3", parentTag)} contentSlug="home-page-quote" {...props} />
  );
}

// id: "046b3cd0-31da-425b-9563-3e15cf2f1761"
// title: ""
// type: :text
// key: "imageUrl"
// parent_id: "f2b321d5-6569-4ba0-98f3-84dd39ede492"
export const ImageUrl046b3cd031da425b95633e15cf2f1761 = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide.svg";

// id: "0c938b00-65dc-4299-8daa-3eb84219b928"
// title: ""
// type: :text
// key: "title"
// parent_id: "395ff4ef-7e48-40e8-be12-2b696fccbabb"
export const Title0c938b0065dc42998daa3eb84219b928 = "Secondary Marketing Title 1";

// id: "ef6f14cd-0d24-44e9-bf34-95944e9c350e"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "2b967b36-5a91-417f-92b1-223f56813936"
export function HeaderTopEf6f14cd0d2444e9Bf3495944e9c350e(props: any) {
  return (
    <DefaultHeaderTop6a917703E60b4ec19fad54e201b22778 parentTag="HeaderTopEf6f14cd0d2444e9Bf3495944e9c350e" widgetId="6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5" {...props} />
  );
}

// id: "eb7c61b5-f8ef-49d4-ae31-1d07ff6ef445"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "379c76f7-5ee2-4f4b-b891-500f919db192"
export const WidgetIdEb7c61b5F8ef49d4Ae311d07ff6ef445 = "4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d";

// id: "1657c36a-6f69-4fb2-a04f-2a84b4ccb395"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "ef6f14cd-0d24-44e9-bf34-95944e9c350e"
export const WidgetId1657c36a6f694fb2A04f2a84b4ccb395 = "6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5";

// id: "135367d4-a126-4f8c-a1cd-9eb28a323e89"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "6149a25a-8aff-4271-8c40-15e87519acf4"
export const NavMenuSlug135367d4A1264f8cA1cd9eb28a323e89 = "members-primary-nav";

// id: "54257a2a-9c26-4f83-a48c-d47d8e2105c5"
// title: ""
// type: :text
// key: "href"
// parent_id: "3ea120c7-8e49-4b00-a8be-6553018b111b"
export const Href54257a2a9c264f83A48cD47d8e2105c5 = "/join";

// id: "349577d6-e40c-4fda-bc3d-12fbad4b5a2b"
// title: ""
// type: :text
// key: "title"
// parent_id: "f97d162c-30b9-45fb-aa29-7c29cb69067f"
export const Title349577d6E40c4fdaBc3d12fbad4b5a2b = "Public Events";

// id: "314269bf-2391-48a1-aa73-8f84d4ccd090"
// title: ""
// type: :text
// key: "label"
// parent_id: "3ea120c7-8e49-4b00-a8be-6553018b111b"
export const Label314269bf239148a1Aa738f84d4ccd090 = "Join Us";

// id: "e5feb74e-b904-4fce-bb0f-2dba96da3623"
// title: ""
// type: :text
// key: "url"
// parent_id: "59409643-0abd-4fb3-9ae1-dea5b2db546c"
export const UrlE5feb74eB9044fceBb0f2dba96da3623 = "/";

// id: "745bd1c7-e00b-415c-a29d-c054135caf06"
// title: ""
// type: :text
// key: "class"
// parent_id: "b7773ab8-93e6-458f-99a7-c989320a1dd4"
export const Class745bd1c7E00b415cA29dC054135caf06 = "navigation";

// id: "fadc8fcc-4cc0-43ad-b6b8-4ea13d83a05b"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "e7bc675d-29a8-4f51-aed1-e26a74e6e0a3"
export const ContentSlugFadc8fcc4cc043adB6b84ea13d83a05b = "home-page-quote";

// id: "1a729104-c261-4f6c-a09e-4e6fb52f0500"
// title: ""
// type: :text
// key: "title"
// parent_id: "a30dddf9-9c4b-4cd5-b2bc-342f5d7aaeb3"
export const Title1a729104C2614f6cA09e4e6fb52f0500 = "Secondary Page";

// id: "c084bd4d-9093-44c5-ab1d-66d536e41625"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "029ec107-3978-4b0e-9326-d630f0eba006"
export const NavMenuSlugC084bd4d909344c5Ab1d66d536e41625 = "footer-nav";

// id: "243829ea-e1f5-4d75-bda9-0bd6f230dbec"
// title: ""
// type: :text
// key: "class"
// parent_id: "59409643-0abd-4fb3-9ae1-dea5b2db546c"
export const Class243829eaE1f54d75Bda90bd6f230dbec = "linked-logo";

// id: "8f0236f8-c5ab-4856-9553-7bb28cf917fe"
// title: ""
// type: :text
// key: "title"
// parent_id: "a98ede74-d2b8-4112-acbc-05e3c397afd3"
export const Title8f0236f8C5ab485695537bb28cf917fe = "Secondary Marketing Title 2";

// id: "7a478d53-1174-4bd3-95c7-47a2828d17da"
// title: ""
// type: :text
// key: "title"
// parent_id: "f964d60c-d7d3-482e-a38a-40a5b19e37ef"
export const Title7a478d5311744bd395c747a2828d17da = "About Us";

// id: "9ef08855-336c-4fd8-8f01-6e3749b7f9f8"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "3a2522c1-9bd8-4aee-8813-27bb913fc017"
export const NavMenuSlug9ef08855336c4fd88f016e3749b7f9f8 = "members-primary-nav";

// id: "bf7c81e8-4378-4e01-91f2-31c25318e76e"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "b7773ab8-93e6-458f-99a7-c989320a1dd4"
export const NavMenuSlugBf7c81e843784e0191f231c25318e76e = "marketing-primary-nav";

// id: "8abaf559-2114-412e-9981-61dbce8d63a9"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "304fa97b-998d-4492-9632-e0e2413519e0"
export const PostSlug8abaf5592114412e998161dbce8d63a9 = "home-page-posts2";

// id: "bd452c75-c56e-44cc-bef8-5f929945581e"
// title: ""
// type: :reference
// key: "label"
// parent_id: "392ec5cc-4ca6-4bea-86a3-008dbeae6c91"
export function LabelBd452c75C56e44ccBef85f929945581e(props: any) {
  return (
    <DefaultLogoF2b321d565694ba098f384dd39ede492 parentTag="LabelBd452c75C56e44ccBef85f929945581e" {...props} />
  );
}

// id: "75849867-d05a-441d-b041-7e4678d5ca02"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "2af94536-9767-4611-8965-530a4f4f6187"
export const PostSlug75849867D05a441dB0417e4678d5ca02 = "about-us-posts";

// id: "9893f076-e688-45e7-98e7-5c1afe52e0d4"
// title: ""
// type: :text
// key: "title"
// parent_id: "133027f0-198f-47d0-a453-77913806344f"
export const Title9893f076E68845e798e75c1afe52e0d4 = "Join Us";

// id: "63d88db5-1aca-4b1e-a1b5-e7d2a74f925d"
// title: ""
// type: :text
// key: "title"
// parent_id: "18a056fe-22db-4154-a276-f60d0652a333"
export const Title63d88db51aca4b1eA1b5E7d2a74f925d = "Leveling";

// id: "483696e8-e361-4e54-8777-55d29233c5fb"
// title: ""
// type: :text
// key: "title"
// parent_id: "dec536df-11ae-4254-8976-bfeffe75d3ab"
export const Title483696e8E3614e54877755d29233c5fb = "Resources";

// id: "f81780f9-3ff2-4092-a1c9-e4a2f25f1c95"
// title: ""
// type: :reference
// key: "label"
// parent_id: "59409643-0abd-4fb3-9ae1-dea5b2db546c"
export function LabelF81780f93ff24092A1c9E4a2f25f1c95(props: any) {
  return (
    <DefaultLogoF2b321d565694ba098f384dd39ede492 parentTag="LabelF81780f93ff24092A1c9E4a2f25f1c95" {...props} />
  );
}

// id: "fac0b652-5a8a-447d-a567-a4b82831cdcb"
// title: ""
// type: :text
// key: "title"
// parent_id: "176c10aa-4f7c-41e1-9d5d-f9d0060ee78e"
export const TitleFac0b6525a8a447dA567A4b82831cdcb = "Join Us";

// id: "bd49830c-06ab-4dcd-82bc-6c383fe4a7a0"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "90cd0012-4bd3-4dcf-bb39-7d070a0f1740"
export const NavMenuSlugBd49830c06ab4dcd82bc6c383fe4a7a0 = "members-primary-nav";

const Components = {
  MarketingCards0b8fc997596e4661B48446fa62912926,
  TitleA8140ffa4e664e1dBf195d0c9d624e24,
  AdminPagesDfe2c6093a5a4385A6bd27081992fa4e,
  DefaultHeader44921788895a4aedA7791dca511702c9,
  MembersAreaHeader111d7a13E2724dde965515fb014dcec1,
  MarketingBody269d447fB8c14e3bBc0fBeedad2c6641,
  AboutUsPage4b26008cD4b249c0B9a2B4b5fbb9e886,
  PublicEventsPage99001a92Aabe432586741ea64387bc51,
  MemberPagesBa865d014924405180b2C178566dfad9,
  MembersAreaDefault7dbe3b1fBb9242f991ef381d4f3656d0,
  SecondaryPage68d96fba49d84b839203Dd8cdbe01d06,
  ResourcesPage671ef0ec709943af822e8eb87166fc2f,
  Label123a7d7b19a649faB24109eeea07e724,
  ShortHeader67c7f2661f67440fB41fD39343ca6b07,
  DefaultLogoF2b321d565694ba098f384dd39ede492,
  PageQuoteD5c8fdfb7715470c87684eca61a00fae,
  JoinUsPage5d71f2beC6234432A32d660046472c47,
  HrefD45755b5A2434f97B69aEee167be90d1,
  FooterD022259896374532B438E139acc5bc24,
  SignupPages4d5d358d8ecf469bA4f4C151267729cd,
  SecondaryMarketingHeroA470ec2c278f490a85e0D0ec554f17ab,
  Header3414f8817ba346f4B83dA24e8f07362b,
  DefaultFooterC4e10cd87a27467e9d3167b01152ced7,
  DefaultHeaderTop6a917703E60b4ec19fad54e201b22778,
  AboutLevelingPageF37e8b35D7f64871A212D510fd67e33e,
  MessagePages2ee8e868794b417280afA150a3cc6cc9,
  HomePageHeroB9e27b8dA7464095834869414698e478,
  SecondaryMarketingHeader395ff4ef7e4840e8Be122b696fccbabb,
  HomePage3fdc304cF45e4e6a98e4Ee94fec50205,
  HomeHeader2b967b365a91417f92b1223f56813936,
  Links029ec10739784b0e9326D630f0eba006,
  HeroA98ede74D2b84112Acbc05e3c397afd3,
  Footer9600907384c0460398d182b096c81a68,
  Header3a2522c19bd84aee881327bb913fc017,
  HeaderC6492429Ce7e4a7990c6473249c61911,
  HeaderF0b82a4177ae4556A1080fa7f4206382,
  WelcomeBack4adc5d7b4b3b41e7Bc8571a6e38d75ff,
  Footer0c12e265Ccff4a898ea48909c55a5f7a,
  Footer773850abC14d40f6A71c9f0ec5e762fe,
  Footer4a927a4b20de479aA04857c8967ee145,
  HeaderA30dddf99c4b4cd5B2bc342f5d7aaeb3,
  PostSlugFc948351Cfdb42a1983d27c8c7aa0e72,
  HeaderBccf4639Aed74379B74cEb3ee12dc401,
  LinkedLogo594096430abd4fb39ae1Dea5b2db546c,
  BodyB71d2d8a1c984776B0b83c8b8ac2e00b,
  Header18a056fe22db4154A276F60d0652a333,
  Hero379c76f75ee24f4bB891500f919db192,
  ButtonClass259308531bdd4c9aAf0cEac714258e3c,
  SecondaryNavC9877a1eE9f9424c90839e42a133ed94,
  CopyrightDaad1c66657249d59e2a91e7dc899199,
  LinkedLogo392ec5cc4ca64bea86a3008dbeae6c91,
  Header176c10aa4f7c41e19d5dF9d0060ee78e,
  TitleA7c3ec1eC24f4ca1Ab6d4dd6f09d914d,
  BackgroundImageC7d88055784f4f699a9977d85e2bf967,
  MarketingCards304fa97b998d44929632E0e2413519e0,
  Button3ea120c78e494b00A8be6553018b111b,
  HeaderF97d162c30b945fbAa297c29cb69067f,
  FooterF229604286c245a89c85A4e5349d8491,
  FooterAfcaa2b0C3d64f2899aeC6623c8e74e1,
  NavigationB7773ab893e6458f99a7C989320a1dd4,
  HeaderF964d60cD7d3482eA38a40a5b19e37ef,
  HeaderTopE2961735Dc7546eaB59152fe4f5d02d8,
  Footer46885dbeF2d347069d4c1139c86c0f2e,
  Title0e173fbdF4164381B78b058233633748,
  HeaderDec536df11ae42548976Bfeffe75d3ab,
  BackgroundImage14c19279411943799257Ec2587c3f567,
  Navigation90cd00124bd34dcfBb397d070a0f1740,
  Logo86000eeeDfb54e19A37b3a0a7f5d3a46,
  HeaderTop6149a25a8aff42718c4015e87519acf4,
  Header133027f0198f47d0A45377913806344f,
  Alt5122a14e342247da90c0422f3b896294,
  Groupflow8d3b0113C4d34b8a92e7B929d42247aa,
  NavMenuSlug65fafe8f5f004854Ba20B6cf67802824,
  Body2af94536976746118965530a4f4f6187,
  QuoteE7bc675d29a84f51Aed1E26a74e6e0a3,
  ImageUrl046b3cd031da425b95633e15cf2f1761,
  Title0c938b0065dc42998daa3eb84219b928,
  HeaderTopEf6f14cd0d2444e9Bf3495944e9c350e,
  WidgetIdEb7c61b5F8ef49d4Ae311d07ff6ef445,
  WidgetId1657c36a6f694fb2A04f2a84b4ccb395,
  NavMenuSlug135367d4A1264f8cA1cd9eb28a323e89,
  Href54257a2a9c264f83A48cD47d8e2105c5,
  Title349577d6E40c4fdaBc3d12fbad4b5a2b,
  Label314269bf239148a1Aa738f84d4ccd090,
  UrlE5feb74eB9044fceBb0f2dba96da3623,
  Class745bd1c7E00b415cA29dC054135caf06,
  ContentSlugFadc8fcc4cc043adB6b84ea13d83a05b,
  Title1a729104C2614f6cA09e4e6fb52f0500,
  NavMenuSlugC084bd4d909344c5Ab1d66d536e41625,
  Class243829eaE1f54d75Bda90bd6f230dbec,
  Title8f0236f8C5ab485695537bb28cf917fe,
  Title7a478d5311744bd395c747a2828d17da,
  NavMenuSlug9ef08855336c4fd88f016e3749b7f9f8,
  NavMenuSlugBf7c81e843784e0191f231c25318e76e,
  PostSlug8abaf5592114412e998161dbce8d63a9,
  LabelBd452c75C56e44ccBef85f929945581e,
  PostSlug75849867D05a441dB0417e4678d5ca02,
  Title9893f076E68845e798e75c1afe52e0d4,
  Title63d88db51aca4b1eA1b5E7d2a74f925d,
  Title483696e8E3614e54877755d29233c5fb,
  LabelF81780f93ff24092A1c9E4a2f25f1c95,
  TitleFac0b6525a8a447dA567A4b82831cdcb,
  NavMenuSlugBd49830c06ab4dcd82bc6c383fe4a7a0
}

export default Components;